import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Pagination } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as S from './styles/styles';
import { doActionGet, doActionPost, doActionPut } from '../../../../helpers/httpRequest';
import apiList, { ALERT_STATUS, NO_OF_ROWS_IN_PAGE, PLEASE_ENTER_REASON, PLEASE_ENTER_RETURN_REFERENCE_NUMBER, PURCHASE_ORDER_CODE, USER_TYPE } from '../../../../lib/constant';
import { formatPriceAndCurrency, isCafeteriaManager, isStoreManager } from '../../../../helpers/utlis';
import { useNavigate } from 'react-router-dom';
import { Order, OrderItem } from './types/types';
import { EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH, VIEW_EMPLOYEE_ORDER_DETAILS, VIEW_EMPLOYEE_ORDER_DETAILS_ROUTE_PATH } from '../../../../routes/routes-constant';
import { calculateSubTotal, calculateTaxTotal, capitalizeFirstLetter, getDisplayStatus } from '../helpers';
import { statusMap } from '../../my-account/my-orders/constants';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../molecules/accordion/accordionHelpers';
import { mergeProducts } from '../../my-account/my-orders/orderHelpers';
import CancelModalPopUp from '../../../atoms/cancel-modal';
import { useNotification } from '../../../../hooks/useNotification';
import ToggleSwitch from '../../../atoms/toggleSwitch/ToggleSwitch';


const theme = createTheme({
  palette: {
    primary: {
      main: '#6F6855',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

const OrderManagement: React.FC = () => {
  const [value, setValue] = useState(0);
  const [orders, setOrders] = useState<Order[]>([]);
  const navigate = useNavigate();
  const [isExpanded,setIsExpanded] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [orderIdForCancellation,setOrderIdForCancellation]  =  useState<number>();
  const { showAlert } = useNotification();
  const [isCreditCardPayment,setIsCreditCardPayment] = useState(false);
  const [isEnable,setIsEnable] = useState(false);
  const [totalCount,setTotalCount] = useState(0);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    if(!isStoreManager()){
      fetchOrders();
    }
  }, []);

  useEffect(()=>{
    if(isStoreManager()){
      fetchOrders(page);
    }
  },[selectedStatuses])

   // const filterOrders = () => {
  //   if (selectedStatuses.includes('all') || selectedStatuses.length === 0) {
  //     return orders;
  //   }

  //   return orders.filter(order => {
  //     if (selectedStatuses.includes('new') && order.status === 'pending') {
  //       return true;
  //     }
  //     if (selectedStatuses.includes('cancel') && (order.status === 'full_cancel' || order.status === 'partial_cancel')) {
  //       return true;
  //     }
  //     if (selectedStatuses.includes('delivered') && (order.status === 'complete' || order.status === 'processing')) {
  //       return true;
  //     }
  //     if (selectedStatuses.includes('return') && (order.status === 'full_return' || order.status === 'partial_return')) {
  //       return true;
  //     }
  //     return false;
  //   });
  // };
  
  const fetchOrders = async (page=0) => {
    try {
      let orderUrl = page===0?apiList.manageOrderList():apiList.manageOrderList(page,NO_OF_ROWS_IN_PAGE,selectedStatuses);
      const response: any = await doActionGet({
        url: orderUrl,
        userType: USER_TYPE.Employee
      });
      setTotalCount(response?.data?.total_count);
      setOrders(response.data.items?.reverse());
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

 
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
   
    if(!isCafeteriaManager()){
      setPage(value);
      fetchOrders(value);
    }
    else{
      setValue(value)
    }
  };

  const calculateTotalPrice = (items: OrderItem[]): number => {
    return items.reduce((sum, item) => sum + item.base_row_total_incl_tax, 0);
  };

  const handleStatusChange = async (order: Order, newStatus: 'pending' | 'preparing' | 'complete' | null = null) => {

    if (newStatus === "complete" && order.status === "complete") {
      navigate(EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH, { 
        state: { 
          orderId: order.entity_id, 
          isSecondPayment: true,
          subTotal: calculateSubTotal(order),
          tax: calculateTaxTotal(order)
        } 
      });
      return;
    }

    try {
      await doActionPost({
        url: apiList.manageOrderStatus,
        data: {
          "orderId": order?.entity_id,
          "status": newStatus
        },
        userType: USER_TYPE.Employee
      });
      fetchOrders(); // Refresh orders after status change
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const handleViewOrder = (orderId: number) => {
    navigate(`${VIEW_EMPLOYEE_ORDER_DETAILS_ROUTE_PATH}/${orderId}`, {state: { orderId: orderId }});
  }

  const handleCancelOrder = (order:Order) => {
    setOrderIdForCancellation(order?.entity_id);
    const isCardPayment = order?.extension_attributes?.payment_list?.filter((payment:any)=>{return payment?.method===PURCHASE_ORDER_CODE})?.length>0?true:false;
    setIsCreditCardPayment(isCardPayment);
    setIsModalOpen(true);
  }

  const orderCancelHandler = async(reason:string,refNo:string,isCardPayment:boolean) =>{
    if(isCardPayment && refNo===""){
      showAlert(PLEASE_ENTER_RETURN_REFERENCE_NUMBER, ALERT_STATUS.error)
    }
    else if(reason===""){
      showAlert(PLEASE_ENTER_REASON, ALERT_STATUS.error)
    }
    else{
      try {
        const data: any = {
          "orderReturnCancelRequest": {
              "reason": reason
          }
      };
      
      // Conditionally add `pos_txn_ref` if `refNo` has a value
      if (refNo) {
          data.orderReturnCancelRequest.pos_txn_ref = refNo;
      }
      
      const response = await doActionPut({
          url: apiList.cancelOrReturnOrder(String(orderIdForCancellation), true),
          userType: USER_TYPE.Employee,
          data: data
      });
        if (response?.data) {
            showAlert(`Order cancellation successful`, ALERT_STATUS.success)
            fetchOrders();
           
        }
    }
    catch (err) { showAlert(`Order cancellation failed!`, ALERT_STATUS.error) }
    finally {setIsModalOpen(false)}
    }
}

  const renderOrderCard = (order: Order) => {
    const isServed = order.status === 'complete';
    const isPaid = order.is_open === "0" || order.is_open === false || order.is_open=== null;
    const canCompletePayment = isServed && !isPaid;


    let buttonText = '';
    let nextStatus: 'pending' | 'preparing' | 'complete' | null = null;

    if (canCompletePayment) {
      buttonText = 'Complete Payment';
      nextStatus = 'complete'; // This will trigger the navigation to the checkout page
    } else if (order.status === 'pending') {
      buttonText = 'In preparation';
      nextStatus = 'preparing';
    } else if (order.status === 'preparing') {
      buttonText = 'Served';
      nextStatus = 'complete';
    }

    const totalPrice = calculateTotalPrice(order.items);
    const displayStatus = getDisplayStatus(order.status);

    return (
      <S.OrderCard key={order.entity_id}>
        <S.OrderDetails>
          <S.OrderHeader>
            <S.OrderTitle>Order #{order.increment_id}</S.OrderTitle>
            <S.OrderDate>{new Date(order.created_at).toLocaleDateString()}</S.OrderDate>
          </S.OrderHeader>
          <S.OrderDetailsWrapper>
            <S.OrderStatusContainer>
              <div className='order-status-section'>
                <div>
              <S.OrderStatusLabel>Order Status:</S.OrderStatusLabel>
              <S.OrderStatusValue>{(displayStatus in statusMap) ? statusMap[displayStatus as keyof typeof statusMap] : capitalizeFirstLetter(displayStatus)}</S.OrderStatusValue>
              </div>
              {isCafeteriaManager() && <div><S.PaymentStatus>{isPaid ? 'Paid' : 'Unpaid'}</S.PaymentStatus></div>}</div>
            </S.OrderStatusContainer>
            <S.OrderStatusContainer><div className='order-employee-section'>{order?.employee_name &&<><S.OrderStatusLabel>Barista Name:</S.OrderStatusLabel>
              <S.OrderStatusValue>{order?.employee_name}</S.OrderStatusValue></>}</div></S.OrderStatusContainer>
              {order?.extension_attributes?.delivery_info?.type ?<S.OrderStatusContainer>{<><S.OrderStatusLabel>Order Type:</S.OrderStatusLabel>
                <S.OrderStatusValue>{order?.extension_attributes?.delivery_info?.type===1?"Eat In "+(order?.extension_attributes?.delivery_info?.notes?"("+order?.extension_attributes?.delivery_info?.notes+")":""):"Take Away"}</S.OrderStatusValue></>}</S.OrderStatusContainer>:<></>}
              {order?.extension_attributes?.reason &&  <S.OrderStatusContainer><div className='order-employee-section'><S.OrderStatusLabel>Remark:</S.OrderStatusLabel>
                <S.OrderStatusValue>{order?.extension_attributes?.reason}</S.OrderStatusValue></div></S.OrderStatusContainer> }
                {order?.extension_attributes?.pos_txn_ref &&  <S.OrderStatusContainer><div className='order-employee-section'><S.OrderStatusLabel>Cancel Txn Ref no. :</S.OrderStatusLabel>
                  <S.OrderStatusValue>{order?.extension_attributes?.pos_txn_ref}</S.OrderStatusValue></div></S.OrderStatusContainer> }
          </S.OrderDetailsWrapper>
          <S.OrderSummary>
            <div>
            {order.items.length} items | {formatPriceAndCurrency(totalPrice)}
            </div>
            {isCafeteriaManager() && order?.extension_attributes?.is_available_for_cancel && (
              <S.SecondaryCancelButton onClick={() => handleCancelOrder(order)}>
                Cancel Order
              </S.SecondaryCancelButton>
            )}
          </S.OrderSummary>
        </S.OrderDetails>
        <S.ProductDetails>
          <S.ProductLabel>Products</S.ProductLabel>
          <S.ProductList>
            {mergeProducts(order.items)?.map((item:any, index:any) => (
              <S.Product key={index}>
                <S.ProductImage>
                  <img
                    src={`${process.env.REACT_APP_PDP_IMAGES_BASE_URL}${item.extension_attributes?.image}`}
                    alt={item.name}
                    onError={(e) => {
                      e.currentTarget.src = '/path/to/fallback/image.jpg';
                    }}
                  />
                </S.ProductImage>
                <S.ProductInfo>
                  <S.ProductName>{item.name}</S.ProductName>
                  <S.ProductPrice>{formatPriceAndCurrency(item.base_row_total_incl_tax)}</S.ProductPrice>
                  <S.ProductQuantity>Qty: {item.qty_ordered}</S.ProductQuantity>
                </S.ProductInfo>
              </S.Product>
            ))}
          </S.ProductList>
          <S.ButtonContainer>
            {isCafeteriaManager() && nextStatus && (
              <S.PrimaryButton onClick={() => handleStatusChange(order, nextStatus)}>
                {buttonText}
              </S.PrimaryButton>
            )}
            <S.SecondaryButton onClick={() => handleViewOrder(order.entity_id)}>
              View Details
            </S.SecondaryButton>
          </S.ButtonContainer>
        </S.ProductDetails>
      </S.OrderCard>
    );
  };

  const handleOrderStatusChange = (status: string) => {
    if (status === 'all') {
      if (selectedStatuses.includes('all')) {
        setSelectedStatuses([]);
      } else {
        setSelectedStatuses(['all']);
      }
    } else {
      if (selectedStatuses.includes('all')) {
        setSelectedStatuses([status]);
      } else {
        setSelectedStatuses(prevStatuses =>
          prevStatuses.includes(status)
            ? prevStatuses.filter(s => s !== status) // Remove if already selected
            : [...prevStatuses, status] // Add if not selected
        );
      }
    }
  };

 
  const toggleSwitchHandler=(isEnable:boolean)=>{
    setIsEnable(isEnable);
  }

  return (
    <ThemeProvider theme={theme}>
      <S.AppWrapper>
        {isCafeteriaManager()?<Box sx={{ width: '100%' }}>
          <S.StickyHeader>
          <CancelModalPopUp isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} onCancel={orderCancelHandler} isCardPayment={isCreditCardPayment}/>
          <div className='order-header'>
          <S.OrdersTitle><div className={isEnable===false?'enabled-title':"disabled-title"}>Orders</div></S.OrdersTitle>
          {/* <ToggleSwitch  label={"Orders"} onchangeHandler={toggleSwitchHandler} value={isEnable}/>
          <S.OrdersTitle><div className={isEnable===true?'enabled-title':"disabled-title"}>Cancelled Orders</div></S.OrdersTitle> */}
          </div>
           {isEnable===false && <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

              <S.StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="order tabs"
                variant="fullWidth"
                TabIndicatorProps={{
                  children: <span className="MuiTabs-indicatorSpan" />,
                }}
              >
                <S.StyledTab label="New" />
                <S.StyledTab label="In preparation" />
                <S.StyledTab label="Served" />
                <S.StyledTab label="Cancelled" />
              </S.StyledTabs>
            </Box>}
          </S.StickyHeader>
         {isEnable===false?<S.ScrollableContent>
            <TabPanel value={value} index={0}>
              <S.TabPanelContent>
                {orders?.filter(order => order?.status === 'pending').map(renderOrderCard)}
              </S.TabPanelContent>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <S.TabPanelContent>
                {orders?.filter(order => order?.status === 'preparing').map(renderOrderCard)}
              </S.TabPanelContent>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <S.TabPanelContent>
                {orders?.filter(order => order?.status === 'complete').map(renderOrderCard)}
              </S.TabPanelContent>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <S.TabPanelContent>
                {orders?.filter(order => order?.status === 'full_cancel').map(renderOrderCard)}
              </S.TabPanelContent>
            </TabPanel>
          </S.ScrollableContent>:<S.ScrollableContent>
          {orders?.filter(order => order?.status === 'full_cancel').map(renderOrderCard)}
            </S.ScrollableContent>}
        </Box>:<Box sx={{ width: '100%' }}>
          <S.StickyHeader>
          <S.OrderTiles>Orders</S.OrderTiles>
          <S.FilterSection>
          <S.OrdersCategoryTitle>All Orders</S.OrdersCategoryTitle>
          <S.Accordian><Accordion className="main-section" expanded={isExpanded}>
          <div className={isExpanded?'is-expanded':'not-expanded'}><AccordionSummary onClick={() => { setIsExpanded(pre =>!pre) }}>
          <S.FilterTitle>Filters</S.FilterTitle>
            </AccordionSummary ></div>
          <S.FilterRoot> <AccordionDetails className="address-list-data">
                {isExpanded && <S.Filters><div className="address-list-section">
                  <div>
                  <div className='checkbox-section'>
                    <label className=''>
                      <input
                        type="checkbox"
                        checked={selectedStatuses.includes('new')}
                        onChange={() => handleOrderStatusChange('new')}
                        className='custom-checkbox'
                      />
                      New
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedStatuses.includes('cancel')}
                        onChange={() => handleOrderStatusChange('cancel')}
                         className='custom-checkbox'
                      />
                      Cancel
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedStatuses.includes('delivered')}
                        onChange={() => handleOrderStatusChange('delivered')}
                         className='custom-checkbox'
                      />
                      Delivered
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedStatuses.includes('return')}
                        onChange={() => handleOrderStatusChange('return')}
                         className='custom-checkbox'
                      />
                      Return
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedStatuses.includes('all')}
                        onChange={() => handleOrderStatusChange('all')}
                         className='custom-checkbox'
                      />
                      All
                    </label>
                  </div>
                </div>
                </div></S.Filters>}
            </AccordionDetails>
            </S.FilterRoot> 
            </Accordion>
            </S.Accordian>
          </S.FilterSection>
          </S.StickyHeader>
          {orders?.map(renderOrderCard)}
          {totalCount>0 &&<S.PaginationContent>
            <div className='paginationContainer'>
              <Pagination count={Math.ceil(totalCount / NO_OF_ROWS_IN_PAGE)} page={page} onChange={handleChange} />
          </div>
          </S.PaginationContent>}
        </Box>}
      </S.AppWrapper>
    </ThemeProvider>
  );
};

function TabPanel(props: { children: React.ReactNode, value: number, index: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default OrderManagement;