import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .basicContainer {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
      }
      .name {
        color: var(--dark-black);
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        padding: 5px 20px 0 20px;
      }

      .designerStory {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
      }

      .storyVideo {
        color: #6f6855;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
      }
      .thumbnailContainer {
        display: flex;
        height: 50.854px;
        padding: 5px 20px;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        gap: 10px;
      }

      .thumbnail {
        height: 40px;
        width: 50px;
        background: black;
      }

      .thumbnailImage {
        height: 40px;
        width: 50px;
      }

      .name-wishlist {
        display: flex;
        justify-content: space-between;
        padding-right: 16px;
        align-items: center;
      }

      .description {
        padding: 0px 20px;
        color: #6f6855;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.48px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        ul{
        margin-block-start: 0px;
        padding-inline-start: 20px;
        }
      }

      .description.expanded {
        -webkit-line-clamp: unset;
      }

      .read-more-link {
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        color: #6f6855;
        padding: 0 20px;
      }
    `;
  }}
`;
