import { addToCartURL, BUTTON_STATE, PDP_CONSTANTS, quoteIdURL, specialOrderAddToCartURL } from "./common/constants"
import { AddToCartProps } from "./common/types"
import { useNotification } from "../../../hooks/useNotification";
import { doActionGet, doActionPost } from "../../../helpers/httpRequest";
import { getLocalStorage } from "../../../helpers/localStorageUtil";
import apiList, { ALERT_STATUS, USER_TYPE } from "../../../lib/constant";
import { addItemToCart_gtm, outOfStock_gtm } from "../../../helpers/GTM";
import { useContext, useEffect } from "react";
import { DataContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";
import { SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH } from "../../../routes/routes-constant";
import { useNavigate } from "react-router-dom";
import * as S from "./styles/addToCartStyles"
import { requestAssistance_gtm } from "../../../helpers/GTM/assistanceGTM";
import { ENQUIRY_STATUSES } from "../assistance/constants";
import { useAuth } from "../../../Context/AuthContext";


const AddToCart = ({ sku, filters, variants, price, maxQuantity, name, special_order_quote_id, user, parentData, quantity, isSimple }: AddToCartProps) => {
    const { showAlert } = useNotification();
    const { dispatch } = useContext(DataContext);
    const navigate = useNavigate();
    const { isEmployeeLoggedIn , isUserLoggedIn } = useAuth();
    const allVariantsSelected = Boolean((Object.keys(filters) ?? []).length === variants?.length);
    let buttonState: BUTTON_STATE
    if (isSimple) {
        buttonState = maxQuantity > 0 ? BUTTON_STATE.ADD_TO_CART : BUTTON_STATE.OUT_OF_STOCK;
    } else {
        if (allVariantsSelected) {
            buttonState = maxQuantity > 0 ? BUTTON_STATE.ADD_TO_CART : BUTTON_STATE.OUT_OF_STOCK;
        }
        else buttonState = BUTTON_STATE.DISABLED;
    }

    useEffect(() => {
        if (buttonState === BUTTON_STATE.OUT_OF_STOCK) {
            const variantInfo: ({ [key: string]: string }) = {};
            variants?.forEach(attribute => {
                const code = attribute.code;
                attribute.options.forEach(option => {
                    if (String(option.value_index) === filters[code]) {
                        variantInfo[code] = option.label;
                    }
                });
            });
            outOfStock_gtm([{ name, price, quantity: null, ...variantInfo }])
        }
    }, [allVariantsSelected, maxQuantity, price, variants, filters, name, buttonState])

    const addToCart = async () => {
        let payload, item
        try {
            if (special_order_quote_id && user === "Employee") {
                //Add the product to the cart using Employee Flow
                const specialOrderQuoteId = special_order_quote_id;
                if (specialOrderQuoteId) {
                    let itemOptions = variants?.filter(value => filters[value?.code] !== '*').map(variant => ({
                        "option_id": variant?.attribute_id,
                        "option_value": Number(filters[variant?.code]),
                    }))
                    payload = {
                        "cartItem": {
                            "sku": sku,
                            "qty": quantity,
                            "quote_id": specialOrderQuoteId,
                            ...(variants?.length ? {
                                "extension_attributes": {},
                                "product_option": {
                                    "extension_attributes": {
                                        "configurable_item_options": parentData ? [...itemOptions, parentData] : itemOptions
                                    }
                                }
                            } : {})
                        }
                    }
                }
                item = await doActionPost({ url: specialOrderAddToCartURL(special_order_quote_id), data: payload, userType: USER_TYPE.Employee });
            } else if (getLocalStorage('user-token')) {

                //Add the product to the cart using Customer Flow
                const quoteId = await doActionPost({ url: quoteIdURL, userType: USER_TYPE.Customer })
                if (quoteId?.data) {
                    let itemOptions = variants?.filter(value => filters[value?.code] !== '*').map(variant => ({
                        "option_id": variant?.attribute_id,
                        "option_value": Number(filters[variant?.code]),
                    }))
                    payload = {
                        "cartItem": {
                            "sku": sku,
                            "qty": quantity,
                            "quote_id": quoteId?.data,
                            ...(variants?.length ? {
                                "extension_attributes": {},
                                "product_option": {
                                    "extension_attributes": {
                                        "configurable_item_options": parentData ? [...itemOptions, parentData] : itemOptions
                                    }
                                }
                            } : {})
                        }
                    }
                }
                item = await doActionPost({ url: addToCartURL, data: payload, userType: USER_TYPE.Customer })
            }

            if (item?.data) {
                const { name, item_id, price, qty } = item?.data ?? {}
                const variantInfo: ({ [key: string]: string }) = {};
                variants?.forEach(attribute => {
                    const code = attribute.code;
                    attribute.options.forEach(option => {
                        if (String(option.value_index) === String(filters[code])) {
                            variantInfo[code] = option.label;
                        }
                    });
                });
                addItemToCart_gtm([{ name, id: item_id, price, quantity: qty, ...variantInfo }])
                dispatch({ type: ACTION_TYPE.increment_cart_item_count, payload: { count: quantity } })
                showAlert(`Product added to cart successfully. `, ALERT_STATUS.success);

                //After adding the product successfully, navigate employee to the special cart, in case of special order flow
                if (special_order_quote_id && user === "Employee") {
                    navigate(SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH, { state: { quoteId: special_order_quote_id } });
                }
            }
            else showAlert(`Add to Cart API - Failure`, ALERT_STATUS.error);
        } catch (error) {
            console.error('Error adding to cart:', error);
            showAlert(`Add to Cart API - Failure`, ALERT_STATUS.error);
        }
    };

    const createEnquiry = async () => {
        try {
            const response = await doActionPost({ url: apiList.createEquiry, userType: USER_TYPE.Customer });
            if (response?.data) showAlert(`An assistance request has been created`, ALERT_STATUS.success);
            const cartData = JSON.parse(getLocalStorage("cartData"));
            const cognito_user_id = cartData?.customer?.custom_attributes?.find((attr: { attribute_code: string; }) => attr.attribute_code === 'cognito_user_id')?.value ?? "";
            const email = cartData?.customer?.email ?? ""
            requestAssistance_gtm({ request_ID: response?.data, cognito_user_id, email })
        } catch (error) {
            showAlert(`Assistance request creation failed`, ALERT_STATUS.error);
            console.error('Error fetching data:', error);
        }
    };
    const getEnquiries = async () => {
        try {
            const response = await doActionGet({ url: apiList.getEnquiries, userType: USER_TYPE.Customer });
            const enquiries = response?.data?.items
            if (enquiries?.length && enquiries.some((enquiry: { status: string; }) => enquiry.status !== ENQUIRY_STATUSES.CLOSE)) showAlert('An assistance request already exists', ALERT_STATUS.info)
            else createEnquiry()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return <S.Content>
        <div className="add-to-cart-container">
            {(buttonState === BUTTON_STATE.OUT_OF_STOCK) && <span className="out-of-stock-text">{PDP_CONSTANTS.out_of_stock}</span>}
            <button className={`add-to-cart-btn ${(buttonState === BUTTON_STATE.DISABLED) ? "fadeOut" : ""}`}
                onClick={() => {
                    if (buttonState !== BUTTON_STATE.DISABLED) {
                        buttonState === BUTTON_STATE.ADD_TO_CART ? addToCart() : (isUserLoggedIn?getEnquiries():addToCart());
                    }
                }}>
                <span className="add-to-cart-text">  {buttonState === BUTTON_STATE.OUT_OF_STOCK
                    ? (isUserLoggedIn?PDP_CONSTANTS.special_order:PDP_CONSTANTS.add_to_cart) : PDP_CONSTANTS.add_to_cart
                }
                </span></button>
        </div>
    </S.Content >
}
export default AddToCart