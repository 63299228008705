import { ENQUIRY_STATUSES } from "../../assistance/constants";
import { StatusColors } from "./types/types";

export const CUSTOMER_LISTING_CONSTANTS = {
    search_bar_placeholder: {
        en: "Search by name or request-id..."
    },
    reason_placeholder: {
        en: "Enter reason for request closure"
    },
    customer_list_label: {
        en: "Customer Requests"
    },
    see_cart_button_label: {
        en: "View cart"
    },
    no_customer_found_label: {
        en: "No Customer Requests Found"
    },
    close_button_label:{
        en:"Close"
    },
    all_request: { en: "All Requests" },
};


export const CUSTOMER_LISTING_MESSAGES = {
  unexpected_error: {
    en: "An unexpected Error occurred",
  },
  status_update_success: {
    en: "Status updated successfully",
  },
  status_update_failed: {
    en: "Failed to update status",
  },
  assign_inquiry_success: {
    en: "Inquiry assigned successfully",
  },
  assign_inquiry_failed: {
    en: "Failed to assign inquiry",
  },
  select_employee: {
    en: "Please select an employee to assign",
  },
  close_reason: {
    en: "Please provide request closure reason",
  },
  fetch_employee_failed: {
    en: "Failed to fetch employees",
  },
};

// status color values
export const statusColors: StatusColors = {
  new: "#008000",
  assign: "#0000FF",
  working: "#F28500",
  closed: "#808080",
  default: "#000000",
};

export const tabMap = {
  0: ENQUIRY_STATUSES.NEW,
  1: ENQUIRY_STATUSES.WORKING,
  2: ENQUIRY_STATUSES.CLOSE,
};
